
.pac-container {
  z-index: 9999;
}

.map-container {
  width: 100%;
  height: 50vh;
}
/* possible states: "idle" | "suggesting" | "navigating" | "interacting"  */

[data-reach-combobox-popover] {
  position: absolute !important;
  z-index: 9998 !important;
}

.combobox-input {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  outline: 1px solid rgba(255, 255, 255, 0.23);
  border-width: 0px;
  background-color: #22273eff;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  margin-bottom: 10px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.dashboardItemContainer {
  display: grid !important;
  grid-template-columns: 3fr 3fr 6fr 1fr;
  column-gap: 1em;
  align-items: 'start';
}

@media (max-width: 576px) {
  .dashboardItemContainer {
    font-size: xx-small !important;
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
  }
}
/* .locationIcon {
  vertical-align: bottom;
  min-width: 20px !important;
} */

/* [data-reach-combobox][data-state="suggesting"] {
background-color: red !important;
z-index: 1300 !important;
}

[data-reach-combobox-popover][data-state="suggesting"] {
  position: absolute !important;
  z-index: 1300 !important;
}
.mapSearchAutocomplete {
  background-color: red !important;
  z-index: 999999;
} */

.editFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.editFooter button {
  margin: 5px;
}