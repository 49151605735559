  /* REACT TEL INPUT OVERRIDES */
  .react-tel-input .form-control {
  background-color: transparent !important;
  color: white;
  }
  .react-tel-input .special-label {
  background-color: #22273eff !important;
  }

  #callNumber {
  color: white;
  text-decoration: none;
  }
  
  /* CALENDAR ROOT PORTAL */
  #root-portal {
    .react-datepicker {
      background-color: #151824ff;
      border-radius: 5px;
      padding: 30px 10px 0px 10px;
      border-color: #151824ff;
      min-height: 465px;
      }
      .react-datepicker__month-container {
      background-color: #151824ff;
      min-height: 465px;
      }
      .react-datepicker__day {
      color: white;
      line-height: 2.5rem;
      }
      .react-datepicker__day:hover {
      background-color: #0059a6;
      }
      .react-datepicker__day--keyboard-selected {
      background: none;
      }
    
      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range {
      background-color: #e91e63;
    
      }
      .react-datepicker__header {
      background-color: #151824ff;
      border-bottom: 1px solid #0059a6;
      }
      .react-datepicker__current-month {
      color: white;
      }
      .react-datepicker__day-name {
      color: white
      }
      .react-datepicker__today-button {
      background-color: #0059a6;
      color: white;
      padding: 10px;
      border-radius: 5px;
      border: none;
      position: absolute;
      bottom: 10px;
      width: 95%;
      }
      .react-datepicker__day--selected:hover {
        background-color: #e91e63;
      }
      .react-datepicker__navigation {
      top: 40px;
      }
      .react-datepicker__header__dropdown {
        padding-top: 10px
      }
      .react-datepicker__month-select {
        color: white;
        background-color: #151824ff;
        border: none;
      }
      .react-datepicker__year-select {
        color: white;
        background-color: #151824ff;
        border: none;
      }
      .react-datepicker__day--outside-month {
        /* visibility: hidden; */
        opacity: 0.2;
        pointer-events: none; /* to prevent click */
      }
  }